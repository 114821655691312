var env = "DEVSHARED";
var API_BASE_URL = "";
var WEB_SOCKET_BASE_URL = "";

if (env === "DEV") {
    API_BASE_URL = 'http://localhost:8000/auth'; 
    WEB_SOCKET_BASE_URL = 'ws://localhost:8000/auth';
} else {
    API_BASE_URL = 'https://eaglepoint.wpbrigade.com/auth';
    WEB_SOCKET_BASE_URL = 'wss://eaglepoint.wpbrigade.com/auth';
}

export { API_BASE_URL, WEB_SOCKET_BASE_URL };
