import React from "react";

const ScoringNotify = ({ sendDataToParent , selectedBuilder, setScoreVisible, message,process}) => {
  const handleClose = () => {
    setScoreVisible(false); // Trigger the state update in the parent
  };
  const sendMessage = (e) => {
    e.preventDefault();
        // Send data to parent
        sendDataToParent(true);
    };
    return (
      <div className="pof2frms-scoring-notify">
          {process === 'Self Evaluation' ? (
              <h3>{process} Complete</h3>
          ) : (
              <>
                  <h3>{process} Complete</h3>
                  <h4>{message}</h4>
                  <p>
                      File: Builder {selectedBuilder} <a href="/builder-rules">View</a>
                  </p>
              </>
          )}
          <a onClick={handleClose} className="pof2frms-popup-close" aria-label="close-popup"></a>
      </div>
  );
};

export default ScoringNotify;
