import React, { useState, useEffect, useRef } from 'react';

const MultiCheckDropdown = ({ selectedItems, setSelectedItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const options = ['Chesmar', 'CitySide', 'David Weekly - Austin', 'David Weekly - Dallas'];
  const allOption = 'All';

  // Toggle dropdown visibility
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (option) => {
    setSelectedItems((prevSelectedItems) => {
      if (option === allOption) {
        // Toggle "All"
        return prevSelectedItems.includes(allOption) ? [] : [allOption];
      } else {
        if (prevSelectedItems.includes(option)) {
          // Unchecking an individual option
          const updatedSelection = prevSelectedItems.filter((item) => item !== option);
          
          // If "All" is selected, unselect it when unchecking any individual option
          if (prevSelectedItems.includes(allOption)) {
            return updatedSelection.filter((item) => item !== allOption);
          }

          return updatedSelection;
        } else {
          // Checking an individual option
          let updatedSelection = [...prevSelectedItems.filter((item) => item !== allOption), option];

          // If "All" was selected previously and you uncheck an individual option, 
          // replace with "All" if all options are now selected
          if (prevSelectedItems.includes(allOption)) {
            updatedSelection = options.filter(opt => opt !== option); // select all options except current one
          }

          // If all options are selected individually, replace with "All"
          const isAllSelectedNow = options.every((opt) => updatedSelection.includes(opt));
          return isAllSelectedNow ? [allOption] : updatedSelection;
        }
      }
    });
  };
  
    // Check if "All" is selected
  const isAllSelected = selectedItems.includes(allOption);

    // Display text for the dropdown
  const displayText = selectedItems.length === 0 ? "Select Builders" : (isAllSelected ? allOption : selectedItems.join(', '));
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div  ref={dropdownRef} className="self-evaluation-dd">
      <div onClick={handleToggle} className={`self-evaluation-trigger ${isOpen ? 'active' : ''}`}>
        {displayText}
      </div>
      {isOpen && (
        <div className="self-evaluation-option">
          <label>
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={() => handleCheckboxChange(allOption)}
            />
            {allOption}
          </label>
          {options.map((option) => (
            <label key={option}>
              <input
                type="checkbox"
                checked={isAllSelected || selectedItems.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiCheckDropdown;