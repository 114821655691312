import {API_BASE_URL,WEB_SOCKET_BASE_URL} from './config';

// Generate magic link
export const generateMagicLink = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-magic-link?email=${encodeURIComponent(email)}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to generate magic link');
        }

        return await response.json();
    } catch (error) {
        console.error('Error generating magic link:', error);
        throw error;
    }
};




export const fetchUsers = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/users`);
        if (!response.ok) {
            throw new Error(`Error fetching users: ${response.statusText}`);
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching users:", error);
    }
};

export const changeUserRole = async (email, newRole) => {
    try {
        const is_admin = newRole === "Admin";
        const response = await fetch(`${API_BASE_URL}/change-role?email=${email}&is_admin=${is_admin}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || `Error changing role: ${response.statusText}`);
        }

        return "Role updated successfully.";
    } catch (error) {
        console.error("Error changing role:", error);
        throw error;
    }
};


export const ProcessOrder = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/process-order/}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({ }),
      });
  
      if (response.ok) {
        console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const RescoringRun = async (index) => {
    try {
        
      const response = await fetch(`${API_BASE_URL}/call-scoring/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({ index }),
      });
  
      if (response.ok) {
        // console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const TestWebSocketLongRun = async (builder_name, specific_result,timestamp,result) => {
    try {
      const response = await fetch(`${API_BASE_URL}/call-compiler/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({ builder_name, specific_result,timestamp,result }),
      });
  
      if (response.ok) {
        // console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const OrdersListingRun = async (files, userId) => {
    if (!files || files.some(file => file.type !== 'application/pdf')) {
      throw new Error('Only PDF files are accepted.');
    }
  
    const formData = new FormData();
    
    // Append user_id and files to FormData
    formData.append('user_id', userId);
    files.forEach(file => {
      formData.append('files', file); // 'files' must match the FastAPI endpoint parameter name
    });
  
    try {
      const response = await fetch(`${API_BASE_URL}/process-file/`, {
        method: 'POST',
        body: formData,
      });
  
      if (!response.ok) {
        const errorDetail = await response.json();
        throw new Error(
          `Error: ${response.status} - ${errorDetail.detail || response.statusText}`
        );
      }
  
      const data = await response.json();
      return data; // Successfully uploaded files
    } catch (error) {
      throw new Error(`File upload failed: ${error.message}`);
    }
  };
  export function setupWebSocket(onMessage) {
    const socket = new WebSocket('ws://localhost:8000/ws');
  
    socket.onopen = () => console.log('WebSocket connection established.');
    socket.onmessage = event => {
      const message = JSON.parse(event.data);
      onMessage(message);
    };
    socket.onclose = () => console.log('WebSocket connection closed.');
    socket.onerror = error => console.error('WebSocket error:', error);
  
    return socket;
  }
  
  

  


export const Scoring = async (builder_name,specific_result,timestamp) => {
    try {
      const response = await fetch(`${API_BASE_URL}/call-scoring/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Specify that you're sending JSON data
        },
        body: JSON.stringify({ builder_name,specific_result,timestamp }),
      });
  
      if (response.ok) {
        console.log("Process started successfully");
      } else {
        console.error("Error starting process:", response.statusText);
      }
    } catch (error) {
      console.error("Error starting process:", error);
    }
};

export const changeUserStatus = async (email, status) => {
    try {
        const response = await fetch(`${API_BASE_URL}/change-status?email=${email}&status=${!status}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) {
            throw new Error(`Error updating user: ${response.statusText}`);
        }

        return "User status updated successfully.";
    } catch (error) {
        console.error("Error updating user status:", error);
        throw error;
    }
};

export const generateOtp = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-otp?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });

        const result = await response.json();

        if (!response.ok) {
            const message = typeof result.detail === 'string'
                ? result.detail
                : (result.detail && result.detail.msg) || 'Failed to generate OTP';
            throw new Error(message);
        }

        return result; // Return OTP response for further use
    } catch (error) {
        console.error("Error generating OTP:", error);
        throw error;
    }
};

export const verifyOtp = async (email, userOtp) => {
    try {
        const response = await fetch(`${API_BASE_URL}/verify-otp?email=${email}&user_otp=${userOtp}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const result = await response.json();

        if (!response.ok || result.msg !== 'SUCCESS') {
            throw new Error('OTP verification failed');
        }

        return result; // Contains auth_token and possibly other data
    } catch (error) {
        console.error('Error verifying OTP:', error);
        throw error;
    }
};

export const resendOtp = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/generate-otp?email=${email}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });

        const result = await response.json();

        if (!response.ok) {
            const message = typeof result.detail === 'string'
                ? result.detail
                : (result.detail && result.detail.msg) || 'Failed to generate OTP';
            throw new Error(message);
        }

        return result; // Return result if needed for additional processing
    } catch (error) {
        console.error('Error resending OTP:', error);
        throw error;
    }
};


export const logoutUser = async (email) => {
    try {
        const response = await fetch(`${API_BASE_URL}/signout?email=${email}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to log out.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during logout:', error);
        throw error;
    }
};


export const addUser = async (new_user_email, is_admin, added_by) => {
    try {
        const response = await fetch(`${API_BASE_URL}/add-user?email=${new_user_email}&added_by=${added_by}&is_admin=${is_admin}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const addFile = async (file,current_time) => {
    try {
        const requestBody = {
            fileContent: file,
            timestamp: current_time,
        };
        const response = await fetch(`${API_BASE_URL}/add_file`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const change_status = async (builder_name,timestamp,status) => {
    try {
        const json_data = {'builder_name':builder_name,'timestamp':timestamp,'status':status}
        
        const response = await fetch(`${API_BASE_URL}/change_status`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json_data),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
}

export const deleteFile = async (builder_name,timestamp) => {
    try {
        const json_data = {'builder_name':builder_name,'timestamp':timestamp}
        
        const response = await fetch(`${API_BASE_URL}/delete_file`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json_data),
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};

export const retrieve_all_builders = async () => {
    console.log(`${API_BASE_URL}/retrieve_all_builders`)
    try {
        const response = await fetch(`${API_BASE_URL}/retrieve_all_builders`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to add user.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
        throw error;
    }
};


export const retrieve_old_files = async () => {
    console.log(`${API_BASE_URL}/retrieve_old_files`)
    try {
        const response = await fetch(`${API_BASE_URL}/retrieve_old_files`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to retrieve old files.');
        }

        return response.json();
    } catch (error) {
        console.error('Error during retrieve old files:', error);
        throw error;
    }
};

export const addTestCase = async (batchName,arg_description ,fileNames, pdfData) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                batch_name: batchName,
                description:arg_description,
                file_names: fileNames,
                pdf_data: pdfData,
            }),
        });

        if (!response.ok) {
            throw new Error(`Error adding/updating test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error adding/updating test case:", error);
        throw error;
    }
};

export const deleteTestCase = async (testCaseName) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/delete/${testCaseName}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error deleting test case:", error);
        throw error;
    }
};

export const getAllTestCases = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-cases/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching test cases: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching test cases:", error);
        throw error;
    }
};

export const getTestCase = async (testCaseName) => {
    try {
        const response = await fetch(`${API_BASE_URL}self_eval_test_cases_api/test-case/${testCaseName}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error fetching test case:", error);
        throw error;
    }
};


export const add_order_document = async (files) => {
    try {
        const response = await fetch(`${API_BASE_URL}/process-file/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(files),
        });
        console.log(`Response: ${response.statusText}`)
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }

        return response.json();
    } catch (error) {
        console.error("Error adding file:", error);
        throw error;
    }
};

export const start_evaluation = async (filters) => {
    console.log(filters)
    const commaSeparatedString = filters.join(', ');
    console.log(typeof(JSON.stringify({ filters: commaSeparatedString })))

    try {
        const response = await fetch(`${API_BASE_URL}/call_self_evaluation?filters=${encodeURIComponent(commaSeparatedString)}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }
        console.log(`Response: ${response.text}`)
        return await response.text();
    } catch (error) {
        console.error("Error adding file:", error);
        throw error;
    }
};


export const retrieve_score_data = async (filters) => {
    console.log(filters)
    const commaSeparatedString = filters.join(', ');
    console.log(typeof(JSON.stringify({ filters: commaSeparatedString })))

    try {
        const response = await fetch(`${API_BASE_URL}/retrieve_score_data?filters=${encodeURIComponent(commaSeparatedString)}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }
        console.log(`Response: ${response.text}`)
        return await response.text();
    } catch (error) {
        console.error("Error adding file:", error);
        throw error;
    }
};


export const start_avg_score_data = async (filters) => {
    console.log(filters)
    const commaSeparatedString = filters.join(', ');
    console.log(typeof(JSON.stringify({ filters: commaSeparatedString })))

    try {
        const response = await fetch(`${API_BASE_URL}/call_avg_score_data?filters=${encodeURIComponent(commaSeparatedString)}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }
        console.log(`Response: ${response.text}`)
        return await response.text();
    } catch (error) {
        console.error("Error adding file:", error);
        throw error;
    }
};



// Verify magic link token
export const verifyToken = async (email, token) => {
    try {
        const response = await fetch(`${API_BASE_URL}/verify-token?email=${encodeURIComponent(email)}&token=${encodeURIComponent(token)}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to verify token.');
        }

        const result = await response.json();
        return result; // Contains the verification status and message
    } catch (error) {
        console.error('Error verifying token:', error);
        throw error;
    }
};


export const retrieve_all_batches = async () => {
    console.log(`${API_BASE_URL}/get-batches`)
    try {
        const response = await fetch(`${API_BASE_URL}/get-batches`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });


        return response.json();
    } catch (error) {
        console.error('Error during adding user:', error);
    }
}

export const sendtorfms = async (batch_name,builder_name,manual_corrections) => {
    
    

    const json_data = {
        batch_name: batch_name,
        builder_name: builder_name,
        manual_corrections:manual_corrections
    };
    console.log(typeof(JSON.stringify(json_data)))

    try {
        const response = await fetch(`${API_BASE_URL}/sendtorfms?batch_name=${batch_name}&builder_name=${builder_name}&manual_corrections=${manual_corrections}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json' // Ensure content type is set
            }

        });

        console.log(`Response: ${response.statusText}`);
        
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.status} - ${response.statusText}`);
        }

        return await response.json(); // Await the JSON response
    } catch (error) {
        console.error("Error adding file:", error);
        throw error; // Rethrow the error for further handling if needed
    }
};

export const filter_dashboard_data = async (filters) => {
    console.log(filters)
    const commaSeparatedString = filters.join(', ');
    console.log(typeof(JSON.stringify({ filters: commaSeparatedString })))

    try {
        const response = await fetch(`${API_BASE_URL}/call_dashboard?filters=${encodeURIComponent(commaSeparatedString)}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            }
        });
        if (!response.ok) {
            throw new Error(`Error adding file: ${response.statusText}`);
        }
        console.log(`Response: ${response.text}`)
        return await response.text();
    } catch (error) {
        console.error("Error adding file:", error);

        throw error;
    }

};

export const retrieveOrderItems = async (batch_name,po_number) => {
    // Replace with your actual base URL
    const baseUrl = `${API_BASE_URL}/retrieve_order_items`;

    try {
        const response = await fetch(`${baseUrl}?batch_name=${batch_name}&po_number=${po_number}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data; // This is the data returned from your API
    } catch (error) {
        console.error('Error fetching order items:', error);
        throw error; // Re-throw the error to handle it in calling code
    }
};
export const retrieveOrderLineItems = async (batch_name,po_number) => {
    // Replace with your actual base URL
    const baseUrl = `${API_BASE_URL}/retrieve_order_line_items`;

    try {
        const response = await fetch(`${baseUrl}?batch_name=${batch_name}&po_number=${po_number}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data; // This is the data returned from your API
    } catch (error) {
        console.error('Error fetching order items:', error);
        throw error; // Re-throw the error to handle it in calling code
    }
};


export const retrievePO = async (batch_name) => {
    // Replace with your actual base URL
    const baseUrl = `${API_BASE_URL}/retrieve_PO_number`;

    try {
        const response = await fetch(`${baseUrl}?batch_name=${batch_name}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data; // This is the data returned from your API
    } catch (error) {
        console.error('Error fetching order items:', error);
        throw error; // Re-throw the error to handle it in calling code
    }
};


export const deleteBatch = async (po_number) => {
    try {
        const response = await fetch(`${API_BASE_URL}/delete_batch/${po_number}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error deleting test case: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error deleting test case:", error);
        throw error;
    }

};
export async function editSoldTo(customerId, customerType,firstName,lastName,postalCode,state,city,county) {
    const url = `${API_BASE_URL}/edit-sold-to`; // Replace with the full API URL if needed
        const payload = {
            customer_id: customerId,
            customer_type: customerType ,
            first_name: firstName ,
            last_name: lastName ,
            postal_code: postalCode,
            state: state ,
            city: city ,
            county: county,
        };
        console.log(payload)
    
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("Error updating sold-to table:", error);
            throw error;
        }
    }

export async function editShipTo(
        batchName,
        firstName,
        lastName,
        address1,
        address2,
        state,
        city,
        postalCode,
        county
    ) {
        const url = `${API_BASE_URL}/edit-ship-to`; // Replace with the full API URL if needed
    
        const payload = {
            batch_name: batchName,
            first_name: firstName,
            last_name: lastName,
            address1: address1,
            address2: address2,
            state: state,
            city: city,
            postal_code: postalCode,
            county: county,
        };
    
        console.log("Payload for editShipTo:", payload);
    
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const result = await response.json();
            return result;
        } catch (error) {
            console.error("Error updating ship-to table:", error);
            throw error;
        }
    }
    export async function deleteLine(lineId, address, poNumber,order_type) {
        // Construct the URL with query parameters
        // console.log(order_type)
        const url = `${API_BASE_URL}/delete_line?line_id=${encodeURIComponent(lineId)}&address=${encodeURIComponent(address)}&po_number=${encodeURIComponent(JSON.stringify(poNumber))}&order_type=${encodeURIComponent(order_type)}`;
        
        try {
            const response = await fetch(url, {
                method: "POST", // Adjust method if your backend expects POST for query params
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(`Error ${response.status}: ${errorDetails.detail || "Failed to delete line."}`);
            }
    
            const result = await response.json();
            return result; // Expected to contain {"status": "success", "message": "Line deleted successfully"}
        } catch (error) {
            console.error("Failed to delete line:", error.message);
            throw error;
        }
    }
    export async function addNewLine(lineId, address, poNumber,order_type) {
        const url = `${API_BASE_URL}/insert_line?line_id=${encodeURIComponent(lineId)}&address=${encodeURIComponent(address)}&po_number=${encodeURIComponent(JSON.stringify(poNumber))}&order_type=${encodeURIComponent(order_type)}`;
        
        try {
            const response = await fetch(url, {
                method: "POST", // Adjust method if your backend expects POST for query params
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            if (!response.ok) {
                const errorDetails = await response.json();
                throw new Error(`Error ${response.status}: ${errorDetails.detail || "Failed to insert line."}`);
            }
    
            const result = await response.json();
            return result; // Expected to contain {"status": "success", "message": "Line deleted successfully"}
        } catch (error) {
            console.error("Failed to insert line:", error.message);
            throw error;
        }
    }
    export async function editLines(orderArray,address,po_number) {
        for (const order of orderArray) {
            // Destructure order object to extract variables
            const { orderType, lineNumber, styleName, colorName, quantity,unitCost ,notes} = order;
    
            // Construct the URL with query parameters
            const url = `${API_BASE_URL}/edit-line?line_id=${lineNumber}&address=${encodeURIComponent(address)}&po_number=${encodeURIComponent(po_number)}&order_type=${encodeURIComponent(orderType)}&style_name=${encodeURIComponent(styleName)}&color_name=${encodeURIComponent(colorName)}&quantity=${quantity}&unit_cost=${unitCost}&notes=${encodeURIComponent(notes)}`;

            console.log(url);
    
            try {
                // Make the backend call
                const response = await fetch(url, {
                    method: "POST", // Use GET since data is sent in the URL
                });
    
                // Handle the response
                if (response.ok) {
                    const result = await response.json();
                    console.log(`Order processed successfully:`, result);
                } else {
                    console.error(`Error processing order:`, response.status, response.statusText);
                }
            } catch (error) {
                console.error(`Failed to process order for lineNumber ${lineNumber}:`, error);
            }
        }
    }
    export async function sendLoggedInUserId(options = {}) {
        try {
            // Retrieve the logged-in user's email from localStorage
            const loggedInUserId = localStorage.getItem('authToken');
    
            if (!loggedInUserId) {
                throw new Error('No logged-in user id found in localStorage.');
            }
    
            // Prepare the request body and headers
            const body = JSON.stringify({ email: loggedInUserId });
            const headers = {
                'Content-Type': 'application/json',
                ...options.headers,
            };
    
            // Send the email to the backend
            const response = await fetch(`${API_BASE_URL}/getLoggedInId`, {
                method: 'POST',
                headers: headers,
                body: body,
                ...options,
            });
    
            // Check if the response is okay
            if (!response.ok) {
                throw new Error(`Failed to send user id: ${response.statusText}`);
            }
    
            return response.json(); // Parse the response as JSON
        } catch (error) {
            console.error('Error sending logged-in user id:', error.message);
            throw error;
        }
    }
    

