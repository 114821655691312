import React, { useState, useEffect, useCallback } from 'react';
import Header from "./common/Header";
import MultiCheckDropdown from './MultiCheckDropdown';
import EvaluationNotify from './EvaluationNotify';
import LineChart from './TestCaseChart';
import { start_evaluation, retrieve_score_data } from '../api';
import { API_BASE_URL, WEB_SOCKET_BASE_URL } from '../config';

const SelfEvaluationHistory = () => {
  const [filters, setFilters] = useState(null);
  const [isEvaluationPopupVisible, setEvaluationPopupVisible] = useState(false);
  const [isScoreComple, setScoreComple] = useState(false);
  const [isTestCaseVisible, setTestCaseVisible] = useState(false);

  const options = ['Chesmar', 'CitySide', 'David Weekly - Austin', 'David Weekly - Dallas'];
  const allOption = 'All';

  // Initialize selectedItems with all options
  const [selectedItems, setSelectedItems] = useState([allOption]);

  const closeEvaluationPopup = (e) => {
    e.preventDefault();
    setEvaluationPopupVisible(false);
  };

  const openEvaluationPopup = (e) => {
    e.preventDefault();
    setEvaluationPopupVisible(true);
  };

  const closeTestCasePopup = () => {
    setTestCaseVisible(false);
  };

  const handleViewDetailsClick = useCallback((e) => {
    e.preventDefault();
    setTestCaseVisible(true);
  }, []);

  const handleSelfEvaluationUpdate = (newData) => {
    setFilters(newData);
  };

  useEffect(() => {
    // Initialize WebSocket connection
    const ws = new WebSocket(`${WEB_SOCKET_BASE_URL}/ws`);

    ws.onmessage = async (event) => {
      const message = JSON.parse(event.data);

      if (message.message === 'Self Evaluation Process Start') {
        console.log(`Process Start`);
      } else if (message.message === 'Self Evaluation Process Completed!') {
        let filters = message.filters;
        console.log(`Filters: ${JSON.stringify(filters)}`);
        setFilters(filters);
        setScoreComple(true);
        setTimeout(() => {
          setScoreComple(false);
        }, 1500);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error.message || error);
    };

    ws.onclose = () => {
      console.warn("WebSocket connection closed");
    };

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    return () => {
      // Cleanup WebSocket connection on component unmount
      ws.close();
    };
  }, []);

  const fetch_scoring_history = useCallback(async () => {
    try {
      const data = await retrieve_score_data(selectedItems);
      console.log(`Data: ${data}`);
      const filters_data = JSON.parse(data);
      setFilters(filters_data);
    } catch (error) {
      console.error('Error during evaluation:', error);
    }
  }, [selectedItems]);

  useEffect(() => {
    fetch_scoring_history();
  }, [fetch_scoring_history]);

  return (
    <div>
      <Header onSelfEvaluationUpdate={handleSelfEvaluationUpdate} />
      <main>
        <div className="pof2frms-container">
          <div className="pof2frms-head">
            <h1>Self Evaluation History</h1>
            <div className="notify-wrapper">
              {isScoreComple && <EvaluationNotify />}
            </div>
            <button className="btn" data-open="#add-file" onClick={openEvaluationPopup}>
              Start Self Evaluation
            </button>
          </div>
          <div className="pof2frms-builder">
            <span>Builders</span>
            <MultiCheckDropdown selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
          </div>
          <div className="chartWrapper">
            <LineChart filters_data={filters} view={handleViewDetailsClick} />
          </div>
        </div>
      </main>

      {isEvaluationPopupVisible && <StartSelfEvaluation selectedItems={selectedItems} closeEvaluationPopup={closeEvaluationPopup} />}

      {isTestCaseVisible && (
        <div className="pof2frms-popup-wrapper pof2frms-popup-testcase" id="compare-file">
          <div className="popup-overlay" onClick={closeTestCasePopup}></div>
          <div className="pof2frms-popup-container">
            <a href="#" className="pof2frms-popup-close" aria-label="close-popup" onClick={closeTestCasePopup}></a>
            <h3>Failed Test Cases</h3>
            <p>Builders: All | Score - 90</p>
            <div className="po2frms-table">
              <table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Test Case</th>
                    <th>Problem</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 1</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder A</td>
                    <td>Testcase 2</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder B</td>
                    <td>Testcase 3</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                  <tr>
                    <td>Builder C</td>
                    <td>Testcase 4</td>
                    <td>Lorem Ipsum is simply dummy text..</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Move the StartSelfEvaluation function outside of SelfEvaluationHistory
function StartSelfEvaluation({ selectedItems,closeEvaluationPopup }) {
  // const [isEvaluationPopupVisible, setEvaluationPopupVisible] = useState(false);
  // const closeEvaluationPopup = (e) => {
  //   e.preventDefault();
    
  //   setEvaluationPopupVisible(false);
    
  // };
  useEffect(() => {
    const performEvaluation = async () => {
      try {
        const data = await start_evaluation(selectedItems);
        console.log(`Data: ${data}`);
      } catch (error) {
        console.error('Error during evaluation:', error);
      }
    };

    performEvaluation();
  }, [selectedItems]);

  return (
    <div className="pof2frms-popup-wrapper pof2frms-popup-center" id="score-file">
      <div className="popup-overlay" onClick={closeEvaluationPopup}></div>
      <div className="pof2frms-popup-container">
        <h3>Performing Self Evaluation</h3>
        <p>
          This will take about 10-15 minutes.<br />
          We will notify you when scoring is complete
        </p>
        <div className="pof2frms-popup-action">
          <button type="button" className="pof2frms-activated" onClick={closeEvaluationPopup}>
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}

export default SelfEvaluationHistory;
