import React, { useRef, useState, useEffect } from "react";
import * as d3 from "d3";

const LineChart = ({ filters_data, view }) => {
  console.log(`Filters: ${filters_data}`);
  const chartRef = useRef();
  const [chartWidth, setChartWidth] = useState(0);

  const data = [
    { timestamp: "2024-01-01", average_score: 100, testcase: 72 },
    { timestamp: "2024-02-01", average_score: 90 , testcase: 72},
    { timestamp: "2024-03-01", average_score: 100, testcase: 72 },
    { timestamp: "2024-04-01", average_score: 85 , testcase: 72},
    { timestamp: "2024-05-01", average_score: 80 , testcase: 72},
    { timestamp: "2024-06-01", average_score: 75 , testcase: 72},
    { timestamp: "2024-07-01", average_score: 60 , testcase: 72},
    { timestamp: "2024-08-01", average_score: 45 , testcase: 72},
    { timestamp: "2024-09-01", average_score: 60 , testcase: 72},
    { timestamp: "2024-10-01", average_score: 95 , testcase: 72},
    { timestamp: "2024-11-01", average_score: 70 , testcase: 72},
    { timestamp: "2024-12-01", average_score: 60 , testcase: 72}
  ];

  // Handle chart resizing
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const parentWidth = chartRef.current.getBoundingClientRect().width;
        setChartWidth(parentWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Update the chart when filters_data changes and is valid
  useEffect(() => {
    if (!filters_data || filters_data.length === 0) {
      filters_data = data;
    }

    const margin = { top: 50, right: 30, bottom: 30, left: 50 };
    const height = 400 - margin.top - margin.bottom;

    // Clear existing SVG
    d3.select(chartRef.current).selectAll("*").remove();

    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", chartWidth - margin.left - margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // X Scale
    const x = d3
      .scaleTime()
      .domain(d3.extent(filters_data, (d) => new Date(d.timestamp)))
      .range([0, chartWidth - margin.left - margin.right]);

    // Y Scale with padding at the bottom (starting point)
    const y = d3
      .scaleLinear()
      .domain([0, 100])
      .range([height, 0]);

    console.log(`Filters Data: ${filters_data}`);
    console.log(`Type Filters Data: ${typeof filters_data}`);

    const tickDates = filters_data.map((d) => new Date(d.timestamp.split(" ")[0]));

    console.log(`Tick Dates: ${tickDates}`);

    // Axes
    svg
      .append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3.axisBottom(x)
          .tickFormat(d3.timeFormat("%b"))
          .tickValues(tickDates)
      )
      .attr("dy", "30px");

    svg
      .append("g")
      .call(d3.axisLeft(y).tickValues([0, 25, 50, 75, 100]))
      
    // Vertical grid lines
    svg
      .selectAll(".vertical-grid")
      .data(tickDates)
      .enter()
      .append("line")
      .attr("class", "vertical-grid")
      .attr("x1", (d) => x(d))
      .attr("y1", 0)
      .attr("x2", (d) => x(d))
      .attr("y2", height)
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4")
      

    // Horizontal grid lines
    const yTicks = [0, 25, 50, 75, 100];
    svg
      .selectAll(".horizontal-grid")
      .data(yTicks)
      .enter()
      .append("line")
      .attr("class", "horizontal-grid")
      .attr("x1", 0)
      .attr("y1", (d) => y(d))
      .attr("x2", chartWidth - margin.left - margin.right)
      .attr("y2", (d) => y(d))
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "4");

    // Line generator
    const line = d3
      .line()
      .x((d) => x(new Date(d.timestamp)))
      .y((d) => y(d.average_score));

    // Draw line
    svg
      .append("path")
      .datum(filters_data)
      .attr("fill", "none")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3)
      .attr("d", line);

    // Draw data points
    svg
      .selectAll("circle")
      .data(filters_data)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(new Date(d.timestamp)))
      .attr("cy", (d) => y(d.average_score))
      .attr("r", 4)
      .attr("fill", "#fff")
      .attr("stroke", "#C29C61")
      .attr("stroke-width", 3);
  }, [chartWidth, filters_data, view]);

  return <div ref={chartRef}></div>;
};

export default LineChart;
