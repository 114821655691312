import React, { useState } from 'react';
import EmailLogin from './EmailLogin';
import OtpScreen from './OtpScreen';
import EmailScreen from './EmailScreen';

const Login = () => {
    const [showOtpScreen, setShowOtpScreen] = useState(false);
    // const [showEmailScreen, setShowEmailScreen] = useState(false);
  	const [email, setEmail] = useState('');

    const handleCodeRequest = (email) => {
        setShowOtpScreen(true); // Show OTP screen
        setEmail(email);
    };

    return (
        <div>
            {showOtpScreen ? (
                <EmailScreen email={email} onBack={() => setShowOtpScreen(false)} onCodeRequest={handleCodeRequest} />
            ) : (
                <EmailLogin onCodeRequest={handleCodeRequest} />
            )}
        </div>
    );
};

export default Login;
